import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import s from "./style.module.css";
import { VoyagesAPI } from "../../api/voyages";
import { TripListItem } from "../../components/TripListItem/TripListItem";
import { useNavigate } from "react-router-dom";

export function TripList() {
  const accessToken = useSelector((store) => store.AUTH.token);
  const userId = useSelector((store) => store.AUTH.iduser);
  const [voyages, setVoyages] = useState([]);
  const navigate = useNavigate();

  async function fetchTrip(userId, accessToken) {
    try {
      const trips = await VoyagesAPI.fetchVoyagesimg(userId, accessToken);
      if (trips.length > 0) {
        setVoyages(trips);
      }
    } catch (error) {
      /*alert(
        `Erreur durant la recherche des voyages ${error.message} ${error.body}`
      );*/
      navigate("/");
    }
  }

  useEffect(() => {
    fetchTrip(userId, accessToken);
  }, []);

  function ClickVoyage(voyage) {
    const voyageID = voyage.IDVoyage;
    navigate("/notelist/" + voyageID);
  }

  return (
    <div>
      <div className={s.container}>
        {voyages.map((voyage) => {
          return (
            <span key={voyage.IDVoyage}>
              <TripListItem
                onClick={() => ClickVoyage(voyage)}
                voyage={voyage}
              />
            </span>
          );
        })}
      </div>
    </div>
  );
}
