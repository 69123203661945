//
import axios from "axios";

const BASE_URL = "https://apitrip.pelloquin.net/v1";

export class VoyagesAPI {
  static async fetchVoyagesimg(userid, access_token) {
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    };

    const response = await axios.get(
      `${BASE_URL}/voyagesimg/${userid}`,
      config
    );
    return response.data;
  }

  static async fetchFraisListe(voyageid, access_token) {
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    };
    const response = await axios.get(`${BASE_URL}/frais/${voyageid}`, config);
    return response.data;
  }

  static async fetchListeTacheVoyage(voyageid, userid) {
    return (
      await axios.get(`${BASE_URL}/listevoyageuser/${voyageid}/${userid}`)
    ).data;
  }

  static async fetchListeTacheUtilisateur(listeutilid) {
    return (await axios.get(`${BASE_URL}/listeutildet/${listeutilid}`)).data;
  }

  static async fetchListeUtilisateur(userid) {
    return (await axios.get(`${BASE_URL}/listeutils/${userid}`)).data;
  }

  static async fetchLoginToken(username, password) {
    const newPost = {
      username: username,
      password: password,
    };
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const response = await axios.post(`${BASE_URL}/token`, newPost, config);
    //console.log("*** reponse LoginToken", JSON.stringify(response.data));
    return response.data;
  }

  static async fetchUtillog(email, password) {
    const newPost = {
      email: email,
      password: password,
    };
    const response = await axios.post(`${BASE_URL}/utillog`, newPost);
    return response.data;
  }

  static async fetchUtillogauto(email, password) {
    return (
      await axios.post(`${BASE_URL}/utillogauto`, {
        email,
        password,
      })
    ).data;
  }

  static async fetchListe(tacheid, tachelibelle, coche) {
    const tache = tachelibelle;
    return (
      await axios.put(`${BASE_URL}/liste/${tacheid}`, {
        tache,
        coche,
      })
    ).data;
  }
}
