import { createSlice } from "@reduxjs/toolkit";

export const expenseSlice = createSlice({
  name: "expenseSlice",
  initialState: {
    totalExpense: 0,
    expenseList: [],
  },
  reducers: {
    addExpense: (currentSlice, action) => {
      //currentSlice.expenseList.push({
      //  ...action.payload,
      const { ImgCateg, ...rest } = action.payload.note;
      currentSlice.expenseList.push({
        ...rest, // Ajoute les autres propriétés sauf ImgCateg
      });
    },
    setTotal: (currentSlice, action) => {
      currentSlice.totalExpense = Number.parseFloat(action.payload);
    },
  },
});

const { addExpense, setTotal } = expenseSlice.actions;

export { addExpense, setTotal };
