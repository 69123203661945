import { useState } from "react";
import { useDispatch } from "react-redux";
import s from "./style.module.css";
import { addExpense } from "../../store/expense/expenseSlice";

export function NoteListItem({ note, onClick }) {
  //const base64Image = voyage.img !== "" ? `data:image/jpg;base64,${voyage.img}` : null;
  const dispatch = useDispatch();
  const [isCardHovered, setIsCardHovered] = useState(false);
  const noteValue = !isNaN(parseFloat(note.MontantTotal))
    ? parseFloat(note.MontantTotal).toFixed(2).replace(".", ",")
    : 0;

  dispatch(addExpense({ note }));

  return (
    <>
      <div
        onClick={onClick}
        onMouseEnter={() => setIsCardHovered(true)}
        onMouseLeave={() => setIsCardHovered(false)}
        //className={s.container}
        style={{
          backgroundColor: isCardHovered ? "#F8FAFD" : "transparent",
          color: isCardHovered ? "grey" : "grey",
        }}
      >
        <div className={s.notelistcontainer}>
          <div className={s.noteitem}>
            <img
              alt={note.Notes}
              src={`data:image/png;base64,${note.ImgCateg}`}
              className={s.noteimage}
            />
            <div className={s.notecontent}>
              <h3 className={s.notetitle}>{note.Notes}</h3>
              <p className={s.descriptionnote}>{note.NomCategorie}</p>
            </div>
            <div className={s.noteamount}>
              {noteValue} {note.DeviseOri}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
