import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import s from "./style.module.css";
import { VoyagesAPI } from "../../api/voyages";
import { NoteListItem } from "../../components/NoteListItem/NoteListItem";
/*import { Sidebar } from "../../components/Sidebar/Sidebar";*/
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export function NoteList(props) {
  const { voyageId } = useParams();
  const accessToken = useSelector((store) => store.AUTH.token);
  const [notes, setNotes] = useState([]);
  const [idvoy, setIdvoy] = useState([]);
  /*const [selectedItem, setSelectedItem] = useState(null);
  const [openedSidebar, setOpenedSidebar] = useState(false);*/
  const navigate = useNavigate();

  async function fetchNote(voyageid) {
    try {
      const noteslist = await VoyagesAPI.fetchFraisListe(voyageid, accessToken);
      if (noteslist.length > 0) {
        setNotes(noteslist);
      }
      setIdvoy(voyageid);
    } catch (error) {
      /*alert(
        `Erreur durant la recherche des voyages ${error.message} ${error.body}`
      );**/
      navigate("/");
    }
  }

  useEffect(() => {
    fetchNote(voyageId);
  }, []);

  function ClickNote(note) {
    //console.log("Clic sur la note " + note.IDFrais);
    //setSelectedItem(note.IDFrais);
    //setOpenedSidebar(!openedSidebar);
  }
  console.log(`notes.length :  + ${notes.length}`);
  return (
    <div className={s.noteslist}>
      {notes.map((note) => {
        return (
          <span key={note.IDFrais}>
            <NoteListItem onClick={() => ClickNote(note)} note={note} />
            {/*<div
              className={`s.container ${
                selectedItem === note.IDFrais ? "s.open s.note-selected" : ""
              }`}
            >
              {selectedItem === note.IDFrais && (
                <Sidebar selectedItem={selectedItem} isOpened={openedSidebar} />
              )}
              </div>*/}
          </span>
        );
      })}
      <div>
        {notes.length === 0 && idvoy > 0 && (
          <div className={s.aucunenote}>Aucune note</div>
        )}
      </div>
    </div>
  );
}
