import "./global.css";
import s from "./style.module.css";
import { Header } from "./components/Header/Header";
import { Outlet } from "react-router-dom";

export function App() {
  return (
    <div className={s.main_container}>
      <div className={s.header}>
        <Header />
      </div>
      <div className={s.body}>
        <Outlet />
      </div>
    </div>
  );
}
