import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    username: null,
    token: null,
    iduser: null,
    firstname: null,
    lastname: null,
  },
  reducers: {
    setCredentials: (state, action) => {
      /*const { email, access_token } = action.payload;*/
      const username = action.payload[0];
      const token = action.payload[1];
      const iduser = Number.parseFloat(action.payload[2]);
      const firstname = action.payload[3];
      const lastname = action.payload[4];
      state.username = username;
      state.token = token;
      state.iduser = iduser;
      state.firstname = firstname;
      state.lastname = lastname;
    },
    logout: (state, action) => {
      state.username = null;
      state.token = null;
      state.iduser = null;
      state.firstname = null;
      state.lastname = null;
    },
  },
});

const { setCredentials, logout } = authSlice.actions;

export { setCredentials, logout };
