import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import s from "./style.module.css";
import { VoyagesAPI } from "../../api/voyages";
import { setCredentials } from "../../store/auth/authSlice";

export function Connexion(props) {
  const [username, setUsername] = useState("demo@pelloquin.net");
  const [password, setPassword] = useState("demo@pelloquin.net");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [connexionError, setconnexionError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setUsername(event.target.value);
    // Vérification de l'e-mail
    if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      setEmailError("Adresse e-mail invalide");
    } else {
      setEmailError("");
    }
  };

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setPassword(event.target.value);

    // Vérification du mot de passe
    if (value.length < 8) {
      setPasswordError("Le mot de passe doit contenir au moins 8 caractères");
    } else {
      setPasswordError("");
    }
  };

  async function fetchUtillog(username, password) {
    try {
      const loginResponseUtil = await VoyagesAPI.fetchLoginToken(
        username,
        password
      );
      if (
        Object.keys(loginResponseUtil).length > 0 &&
        loginResponseUtil.iduser > 0
      ) {
        const email = loginResponseUtil.email;
        const access_token = loginResponseUtil.access_token;
        const iduser = loginResponseUtil.iduser;
        const firstname = loginResponseUtil.nom;
        const lastname = loginResponseUtil.prenom;

        AppelTripList(email, access_token, iduser, firstname, lastname);
      } else {
        setconnexionError("Erreur de connexion");
      }
    } catch (error) {
      alert(
        "Erreur de connexion " +
          error.message +
          "  " +
          error.body +
          "  " +
          error.header +
          "  " +
          error
      );
    }
  }

  const AppelTripList = (email, access_token, iduser, firstname, lastname) => {
    dispatch(
      setCredentials([email, access_token, iduser, firstname, lastname])
    );
    navigate("/triplist", { replace: true });
  };

  const handleClick = () => {
    // Vérification finale avant la soumission
    if (emailError || passwordError) {
      console.log("Il y a des erreurs dans le formulaire");
      return;
    } else {
      fetchUtillog(username, password);
    }
  };

  return (
    <div className="container">
      <div className="login-container">
        <h1>Connexion</h1>
        <form>
          {connexionError && (
            <div className={s.connexionerror}>{connexionError}</div>
          )}
          <label htmlFor="username">Identifiant</label>
          <input
            type="text"
            id="username"
            name="username"
            value={username}
            onChange={handleEmailChange}
            autocomplete="off"
          />
          {emailError && <div className={s.texterror}>{emailError}</div>}

          <label htmlFor="password">Mot de passe</label>
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={handlePasswordChange}
            autocomplete="off"
          />
          {passwordError && <div className={s.texterror}>{passwordError}</div>}
          <a href="#">Mot de passe oublié ?</a>

          <button className={s.button} type="button" onClick={handleClick}>
            Connexion
          </button>
        </form>
      </div>
    </div>
  );
}
