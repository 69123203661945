import { useState } from "react";
import s from "./style.module.css";
import moment from "moment";

export function TripListItem({ voyage, onClick }) {
  const base64Image =
    voyage.img !== "" ? `data:image/jpg;base64,${voyage.img}` : null;
  const budgetValue = !isNaN(parseFloat(voyage.Budget))
    ? parseFloat(voyage.Budget).toFixed(0)
    : 0;
  const reelValue = !isNaN(parseFloat(voyage.depense))
    ? parseFloat(voyage.depense).toFixed(0)
    : 0;
  const [isCardHovered, setIsCardHovered] = useState(false);

  function dateFormater(dateAFormater) {
    const date = moment(dateAFormater);
    const formattedDate = date.format("DD/MM/YYYY");
    return formattedDate;
  }

  return (
    <>
      <div
        onClick={onClick}
        onMouseEnter={() => setIsCardHovered(true)}
        onMouseLeave={() => setIsCardHovered(false)}
        className={s.container}
        /*style={{ backgroundColor: isCardHovered ? "blue" : "transparent" }}*/
        style={{
          backgroundColor: isCardHovered ? "#F8FAFD" : "white",
          color: isCardHovered ? "grey" : "grey",
        }}
      >
        <div className={s.item}>
          <div className={s.partie_image}>
            <h5 className="">{voyage.NomVoyage}</h5>
            <img
              alt={voyage.NomVoyage}
              src={`data:image/png;base64,${voyage.img}`}
              className={s.image}
            />
          </div>
          <div className={s.partie_description}>
            <div>
              Du {dateFormater(voyage.DebutVoyage)} au{" "}
              {dateFormater(voyage.FinVoyage)}
            </div>
            <p>{budgetValue > 0 ? `${budgetValue} €` : ""}</p>
          </div>
        </div>
      </div>
    </>
  );
}
