import s from "./style.module.css";
import { Logo } from "../Logo/Logo";
//import { Row, Col, Button } from "react-bootstrap";
import { ButtonPrimary } from "./../ButtonPrimary/ButtonPrimary";
import logo from "./../../assets/images/mytrip.png";
import { useNavigate } from "react-router-dom";

export function Header(props) {
  const navigate = useNavigate();
  return (
    <div className={`row ${s.container}`}>
      <div className="col-xs-12 col-sm-4">
        <Logo
          title="My trip"
          subtitle="Gère tes voyages"
          image={logo}
          text="Mytrip"
          onClick={() => navigate("/")}
        />
      </div>
      <div className={`col-xs-12 col-sm-8 text-end`}>
        <ButtonPrimary onClick={() => navigate("/PageConstruction")}>
          Ajouter un voyage
        </ButtonPrimary>
      </div>
    </div>
  );
}
