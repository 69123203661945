import React from "react";
import ReactDOM from "react-dom/client";
import { StrictMode } from "react";
import { Provider } from "react-redux";
import { store } from "./store";

import { App } from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Connexion } from "./pages/Connexion/Connexion";
import { TripList } from "./pages/TripList/TripList";
import { TripItem } from "./pages/TripItem/TripItem";
import { NoteList } from "./pages/NoteList/NoteList";
import { NoteItem } from "./pages/NoteItem/NoteItem";
import { CheckList } from "./pages/CheckList/CheckList";
import { PageNotFound } from "./pages/PageNotFound/PageNotFound";
import { PageConstruction } from "./pages/PageConstruction/PageConstruction";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route index element={<Connexion />} />
          <Route path="/" element={<App />}>
            <Route path="/triplist/" element={<TripList />} />
            <Route path="/trip/:tripId" element={<TripItem />} />
            <Route path="/notelist/:voyageId" element={<NoteList />} />
            <Route path="/note/:noteId" element={<NoteItem />} />
            <Route path="/checklist/" element={<CheckList />} />
            <Route path="/PageConstruction/" element={<PageConstruction />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </StrictMode>
);
